<template>
	<div class="footer-main" v-if="showFooter">
		<!--<div class="footer-top" v-if="$route.name === 'subscribe'">
			<div class="wrapper">
				<div class="footer_text-2">
					Нажимая кнопку «Получить доступ», вы подтверждаете заказ услуги «Доступ к «Игровому порталу» на условиях
				</div>
			</div>
		</div>-->
		
		<footer class="footer">
			<div class="wrapper">
				<div class="footer_row">
					<div class="footer_text">12+ © Росконтент, {{ year() }}</div>
					<router-link to="/info" target="_blank" class="footer_link">Условия сервиса</router-link>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
	import moment from 'moment';

	export default {
		methods: {
			year() {
				return moment().format('Y')
			}
		},
        computed: {
            showFooter() {
                return this.$route.name !== 'play';
            }
        },
	}
</script>
