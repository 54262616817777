<template>
	<div>
		<div class="policy">
			<div class="wrapper">
				<h1>ИГРОВОЙ ПОРТАЛ</h1>
				<h2>Пользовательское соглашение</h2>

				<p>Данные положения являются обязательными и озвучиваются в настоящих правилах на предоставление услуги «Подписка на Сервис «Tele2 игровой портал» Абонентам Оператора Tele2 (далее — Абонент).</p>

				<p>1. Воспользоваться услугой «Подписка на Сервис «Tele2 игровой портал» (далее — «Подписка») на указанных ниже условиях могут любые физические лица, являющиеся Абонентами Оператора сотовой связи Tele2 (далее — «Оператор»).</p>

				<p>2. Для заказа услуги «Подписка» Абонент должен располагать оборудованием, необходимым для оформления заказа и использования услуги, включая, но не ограничиваясь: мобильный телефон (или другое мобильное устройство доступа), который находится в рабочем состоянии и обеспечивает Абоненту доступ к услугам Оператора, посредством подключения данного конечного оборудования к сети связи Оператора.</p>

				<p>3. В рамках услуги «Подписка» Абоненту обеспечивается доступ к контенту (html5-игры, приложения и пр.) games.tele2.ru— далее «Сервис». Оператор обеспечивает Абоненту доступ к Сервису посредством сети связи Оператора и несет ответственность за работоспособность сети связи и качественное предоставление доступа к Сервису посредством сети связи.</p>

				<p>4. Сервис предоставляется Сервис-провайдером. Сервис-провайдер несет полную ответственность за соответствие информации, предоставляемой Абоненту действительности, а также действующему законодательству и нормативно-правовым актам Российской Федерации.
					Оператор не несет ответственности за возможный причиненный Абоненту ущерб, вызванный противоправными действиями Сервис-провайдера, а также за содержание Сервиса и любую информацию, размещенную на сайтах Сервис-провайдера или его партнеров, к которым Абонент получил доступ.</p>

				<p>5. Сервис-провайдером является: Общество с ограниченной ответственностью «Фабрика мобильного контента», ОГРН: 1057748975393, ИНН: 7724560137, юридический адрес: 140105, Московская область, Раменский район, г. Раменское, Северное шоссе, д. 10, офис 303</p>

				<p>6. Заказ услуги «Подписка» осуществляется Абонентом в целях получения доступа к Сервису путем: <br>
					- отправки USSD-запроса: *251#;<br>
					- нажатия (активации) кнопки «Оформить подписку» на web-ресурсе games.tele2.ru с абонентского устройства, подключенного к сети связи Оператора и указания абонентского номера по требованию web-ресурса. Ввод абонентского номера не требуется, если он в автоматическом режиме идентифицирован сетью связи Оператора;<br>
					- ответа на push-сообщение, отправка которого инициирует совершение любого из перечисленных действий: отправки USSD-запросов: *251#;
					При предоставлении услуги «Подписка» могут использоваться различные способы привлечения Абонентов к заказу услуги, включая, но не ограничиваясь: баннеры, тизеры, редиректы.</p>

				<p>7. Осуществляя заказ услуги «Подписка» в порядке, предусмотренном п. 6 настоящих Правил, Абонент:<br>
					- подтверждает свое ознакомление и согласие с настоящими Правилами;<br>
					- подтверждает свое согласие на получение услуги «Подписка» в соответствии с условиями, изложенными в настоящих Правилах;<br>
					- подтверждает факт выполнения всех требований, предъявляемых настоящими Правилами к Абоненту, а также принимает на себя обязательство следовать этим требованиям в период использования услуги «Подписка»;<br>
					- подтверждает, что дает Оператору и Сервис-провайдеру согласие на обработку персональных данных, указанных Абонентом при заказе услуги «Подписка», полученных Оператором при заключении договора об оказании услуг связи, а также полученных в автоматическом режиме от технических средств Сервис-провайдера. Обработка данных осуществляется в соответствии с действующим законодательством Российской Федерации и в соответствии с положениями Политики конфиденциальности при пользовании услугами Tele2. Абонент обязан самостоятельно ознакомиться с Политикой конфиденциальности Tele2 (доступна по адресу: apps.tele2.ru/privacy-policy.pdf) до заказа услуги «Подписка» и предоставления информации. Совершение действий, указанных в п.6. будет являться безусловным подтверждением и согласием Абонента с условиями Политики конфиденциальности Tele2. Данные обрабатываются в целях надлежащего предоставления Абоненту услуги «Подписка» следующими способами: сбор, запись, систематизация, накопление, хранение, извлечение, использование, предоставление, блокирование, удаление, уничтожение;<br>
					- выражает согласие на отправку ему с короткого сервисного номера Tele2-games бесплатных SMS-сообщений, напоминающих Абоненту о содержании, стоимости, порядке отказа от услуги «Подписка», обновлениях Сервиса и его дополнительного функционала и обязуется оплачивать услуги связи и/или иные услуги Оператора, оказанные при использовании услуги «Подписка»;<br>
					- гарантирует, что будет пользоваться Сервисом в рамках Подписки только в личных целях. Использование Сервиса любым способом, не предусмотренным настоящими Правилами, включая, но не ограничиваясь, его перепродажа или распространение иным образом (как в целом, так и их составных частей) не допускается;<br>
					- обязуется не осуществлять действия, направленные на нарушение функционирования Сервиса или получение несанкционированного доступа к программному обеспечению Сервиса.</p>

				<p>8. Выполнение Абонентом указанных в п. 6 настоящих Правил действий означает согласие Абонента на получение Сервиса, а также на передачу номера мобильного телефона Абонента третьим лицам, в том числе Сервис-провайдеру для предоставления Сервиса.</p>

				<p>9. В рамках услуги «Подписка» Абонент получает возможность доступа к дополнительному функционалу Сервиса – онлайн- и android-играм.</p>

				<p>10. После выполнения Абонентом действий, указанных в пункте 6 настоящих Правил, услуга «Подписка» предоставляется Абоненту на платной основе. Факт начала оказания услуги подтверждается отправкой Абоненту с короткого сервисного номера Tele2-games SMS-сообщения, информирующего Абонента о факте подключения и условиях предоставления услуги «Подписка» и отказа от неё.</p>

				<p>11. Оплата услуги «Подписка» осуществляется за счет ранее авансированных денежных средств в оплату услуг связи и отраженных на лицевом счете Абонента в системе Оператора Tele2.</p>

				<p>12. Стоимость услуги «Подписка» для Абонента составляет 10.00 рублей в день, включая НДС.</p>

				<p>13. В случае невозможности списания с лицевого счета Абонента денежных средств за использование услуги «Подписка» в полном размере (в силу отсутствия достаточного количества денежных средств на лицевом счете Абонента), услуга «Подписка» может быть предоставлена Абоненту с последующей дотарификацией.</p>

				<p>14. Абонент вправе в любое время отказаться от услуги «Подписка», одним из указанных ниже способов:
					- отправки USSD-запроса: *251*0#.</p>

				<p>15. При отказе от услуги «Подписка» доступ к дополнительному функционалу Сервиса предоставляется Абоненту до истечения ранее оплаченного Абонентом периода использования услуги.</p>

				<p>16. В случае прекращения оказания Абоненту услуги «Подписка» восстановление услуги «Подписка» производится только после повторного выполнения Абонентом действий, указанных в пункте 6 настоящих Правил.</p>

				<p>17. В случае некорректного предоставления услуги «Подписка» или наличия претензий, касающихся ее подключения и предоставления, Абонент Оператора обязан уведомить об этом Сервис-провайдера по адресу электронной почты support_tele2@roscontent.ru .</p>

				<p>18. Настоящие Правила в соответствии с действующим законодательством Российской Федерации являются предложением (публичной офертой) заключить договор присоединения о предоставлении услуги «Подписка» на изложенных в настоящих Правилах условиях.</p>

				<p>19. Настоящие Правила могут быть в одностороннем порядке изменены путем размещения новой редакции Правил по адресу: games.tele2.ru/info, в этом случае новая редакция Правил будет считаться вступившей в силу с момента такого размещения.</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>