<template>
	<div></div>
</template>

<script>
	var globalIframe, globalUrl;
	
	export default {
		data() {
			return {
				id: this.$route.params.id,
				game: null,
				auth: window.auth,
                iframe: null,
                resized: false
			}
		},
		
		created() {
			this.axios.get('/games/' + this.id + '/').then(({data}) => {
				this.game = data;
				
				this.initIframe(this.game);
			});
		},
        mounted() {
            $(window).on('resize orientationchange webkitfullscreenchange mozfullscreenchange fullscreenchange',  () => {
                if (this.resized) {
                    return;
                }
                this.resized = true;
                
                $(document).find('iframe').remove();
                
                this.initIframe(this.game);
                
                this.resized = false;
            });
        },

        methods: {
			initIframe(game) {
				this.iframe = document.createElement('iframe');
                this.iframe.id = 'game-frame';
                this.iframe.className = 'game-frame';
                this.iframe.src = game.api_url;
                this.iframe.setAttribute('frameborder', '0');
                this.iframe.setAttribute('name', window.location.href);

				var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
				var eventer = window[eventMethod];
				var messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message';
				eventer(messageEvent, (e) => {
					switch (e.data.type) {
						case 'send':
							this.sendScore({
								type: e.data.label,
								level: e.data.level,
								score: e.data.score
							});
							break;
					}
				}, false);
				document.getElementsByTagName('body')[0].appendChild(this.iframe);
				globalIframe = this.iframe;
			},
			playGame(url) {
				globalUrl = url;
				globalIframe.contentWindow.postMessage({
					message: 'callbackExecuted'
				}, url);
			},
			sendScore(obj) {
				if (this.game.for_raiting) {
					this.axios.post('/games/' + this.id + '/scores/', {score: obj.score});
				}
			},
		}
	}
</script>

<style>
.open-fullscreen {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 9999999;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    cursor: pointer;
}
</style>
